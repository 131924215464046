.tooltip-wrapper {
    .tooltip-wrapper__add__btn {
        position: relative;
        float: right;
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        background-position: center;
        background-size: 25px 25px;
    }

    .tooltip-wrapper__new-window__btn {
        position: relative;
        float: right;
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        background-position: center;
        background-size: 25px 25px;
    }
}

.marker-on-map {
    .marker-on-map__custom-marker-deliver {
        height: 30px;
        width: 30px;
        background-color: #8b83ba;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: black;
        display: inline-block;
        text-align: center;
        padding-top: 5px;
    }

    .marker-on-map__custom-marker-pickup {
        height: 30px;
        width: 30px;
        background-color: #f89322;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: black;
        display: inline-block;
        align-self: center;
        padding-top: 5px;
    }
}
