// Form Status BTN
.form-status-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-right: 20px;
    padding-left: 20px;
    margin: 10px;
    height: 40px;
    border: 1px solid #ffe4d6;
    border-radius: 10px;

    .form-status-btn-icon {
        margin-right: 10px;
    }
}

// Form Btn Not Saved
.not-saved {
    border: 1px solid #ffe4d6;
}

// Form Btn Saved
.saved {
    border: 1px solid #d0ffbd;
}

// secondary btn
.secondary-btn {
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(bottom right, #f63c68 0%, #f63c68 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #f63c68 0%, #f63c68 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #f63c68 0%, #f63c68 100%);
}

.secondary-btn:hover {
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(bottom right, #f63c68 0%, #cc234b 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #f63c68 0%, #cc234b 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #f63c68 0%, #cc234b 100%);
}

// header bar client select
.x-client-name {
    color: $theme-color;
    font-weight: bold;
    margin-left: 10px;
    font-size: 14px;
    border: 1px solid $theme-color-3;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer !important;
}

.x-client-name-modal {
    color: $theme-color;
    font-weight: bold;
    font-size: 22px;
    border: 1px solid $theme-color-3;
    border-radius: 5px;
    cursor: pointer !important;
}

// header bar merchant select
.x-merchant-name {
    cursor: pointer !important;
    color: $theme-color-2;
    font-weight: bold;
    margin-left: 10px;
    font-size: 14px;
    border: 1px solid $theme-color-3;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}
@media screen and (max-width: 650px) {
    .x-client-name {
        background-color: white;
    }
    .x-merchant-name {
        background-color: white;
    }
}

// Custom Table
.tableData-row-disabled {
    background-color: rgba(236, 240, 241, 0.5);
    pointer-events: none;
}

// Select Profile Page
// src\containers\Auth\Select-Profile\index.js
.auth-card-custom-selected {
    background-color: rgba(236, 240, 241, 0.9);
    cursor: pointer !important;
}
// Select Profile Page
// src\containers\Auth\Select-Profile\index.js
.auth-card-custom-selected:hover {
    background-color: rgba(236, 240, 241, 0.9);
    cursor: pointer !important;
}

// Select Profile Page
// src\containers\Auth\Select-Profile\index.js
.auth-card-custom-not-selected:hover {
    background-color: rgba(236, 240, 241, 0.9);
    cursor: pointer !important;
}

// Table Dropdown filter
.table-filter {
    .dropdown-menu.dropdown-menu-right {
        right: -50px !important;
    }
}

// custom table hide scroll of no data  ( data.docs.length===0)
// .custom-table-container {
//     .custom-table-none {
//         .table-responsive {
//             overflow: hidden !important;
//         }
//     }
// }

.picking-task-order-edit-column {
    cursor: pointer !important;
}

.packaging-switch {
    margin-right: 10px;
    .react-switch-bg {
        /* display: none; */
        width: 125px !important;
        div {
            justify-content: none;
            margin-left: 20px !important;
        }
    }

    .switch-on {
        .react-switch-handle {
            height: 26px;
            width: 26px;
            background: rgb(255, 255, 255);
            display: inline-block;
            cursor: pointer;
            border-radius: 50%;
            position: absolute;
            transform: translateX(100px) !important;
            top: 1px;
            outline: 0px;
            border: 0px;
            transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
        }
        .react-switch-bg {
            div {
                justify-content: none;
                margin-left: 25px !important;
                font-weight: bold;
                font-size: 25px;
            }
        }
    }
    .switch-off {
        .react-switch-bg {
            div {
                justify-content: none;
                margin-right: 50px !important;
                font-weight: bold;
                font-size: 25px;
            }
        }
    }
}

.form-card {
    border-radius: 15px;
    background-color: #fafafa;
    border: 1px solid #d8d4cc;
    padding: 10px;
    border-width: 1px;
}

.pointer-cursor {
    cursor: pointer !important;
}

.table-restore-btn {
    cursor: pointer !important;
    background-color: #39bdc8;
    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    letter-spacing: 2px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.scootix-btn {
    padding: 5px;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #f58120 0%, #fcb524 100%);
    .circle {
        border-radius: 50px;
        display: none;
    }
}

.scootix-btn-radius {
    cursor: pointer !important;
    height: 45px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(bottom right, #ffcf4b 0%, #f09953 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    border-radius: 30px;
    border-color: transparent !important;
    border-right-color: transparent !important;
    color: #fff;
    border: none;
    font-weight: bold;
    // ffcf4b
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        font-size: 16px;
        margin-left: 5px;
        color: #fff;
        padding-top: 8px;
    }

    .is-click {
        cursor: pointer;
    }

    span {
        margin-top: 2px;
    }
}

.scootix-btn-radius:hover {
    background-image: -ms-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
}

.scootix-btn-circle {
    cursor: pointer !important;
    padding: 10px;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #f58120 0%, #fcb524 100%);
    border-radius: 50px;
    i {
        color: #fff;
    }
}

.scootix-btn-circle:hover {
    background-image: -ms-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #cccccb 0%, #fc7b11 100%);
}

.scootix-btn-circle-icon {
    cursor: pointer !important;
    padding: 10px;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #f58120 0%, #fcb524 100%);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    i {
        color: #fff;
    }
}

.scootix-btn-circle-icon:hover {
    background-image: -ms-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #fdbf15 0%, #fc7b11 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #cccccb 0%, #fc7b11 100%);
}

.scootix-form-btn {
    cursor: pointer !important;
    background-color: $theme-color;
    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.form-delete-btn {
    cursor: pointer !important;
    background-color: #362c64;
    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    letter-spacing: 2px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.scootix-bg-radiant {
    background-image: -ms-linear-gradient(bottom right, #ffcf4b 0%, #f09953 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
}

.save-btn {
    min-width: 120px !important;
}

.square-btn {
    cursor: pointer !important;
    height: 45px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(bottom right, #ffcf4b 0%, #f09953 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    border-color: transparent !important;
    border-right-color: transparent !important;
    color: #fff;
    font-weight: bold;
    // ffcf4b
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        font-size: 16px;
        margin-left: 5px;
        color: #fff;
        padding-top: 8px;
    }

    .is-click {
        cursor: pointer;
    }
}

.square-btn:hover {
    background-image: -ms-linear-gradient(bottom right, #ffcf4b 0%, #f09953 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
}

.btn {
    cursor: pointer !important;
}

.btn-custom {
    cursor: pointer !important;
}

.pointer {
    cursor: pointer !important;
}

.export-btn {
    cursor: pointer !important;
    background-color: #17b978;
    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.cancel-btn {
    cursor: pointer !important;
    background-color: #5d5d5a;
    border-radius: 14px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;
}

.export-btn-table {
    cursor: pointer !important;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(bottom right, #ffcf4b 0%, #f09953 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
    border-radius: 24px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.scootix-btn-circle-np {
    cursor: pointer !important;
    // padding: 10px;
    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #f58120 0%, #fcb524 100%);
    border-radius: 50px;
    i {
        color: #fff;
    }
}

/*=======  Back to top  =======*/
.back-to-top {
    position: fixed;
    right: 0;
    bottom: 40px;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 40px;
    background-color: #fcd462;
    z-index: 99;
    color: $white;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
    &.active {
        visibility: visible;
        opacity: 1;
        right: 40px;
    }
    // &:hover {
    //     background-color: #fff;
    // }
}

.form-download-btn {
    cursor: pointer !important;
    background-color: #0bbe5c;
    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    letter-spacing: 2px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }
}

.scootix-custom-btn {
    cursor: pointer !important;

    /* IE10 Consumer Preview */
    background-image: -ms-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #f58120 0%, #fcb524 100%);
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #f58120 0%, #fcb524 100%);

    border-radius: 14px;
    border: none;
    color: #fff;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;

    display: flex;

    justify-content: center;
    align-items: center;
    align-content: center;

    letter-spacing: 2px;

    i {
        color: #fff;
    }
    .left-icon {
        margin-right: 5px;
    }
    .h5 {
        margin-left: 5px;
        color: #fff;
    }
    .h6 {
        margin-left: 5px;
        color: #fff;
    }

    span {
        margin-left: 10px;
    }
}

.custom-drop-btn {
    color: #fff;
    border: none;
    padding: 5px 10px 5px 15px;
    align-items: center;
    display: flex;
    border-radius: 7px;
}

#myInput {
    box-sizing: border-box;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 14px 0px 12px 22px;
    border: none;
    outline: none;
    border-bottom: 1px solid #ddd;
}

.pie-chart {
    .apexcharts-canvas text {
        fill: #fff !important;
        flex: 1 !important;
    }
}

.tab-bar {
    .developer-portal-nav .nav-item .active {
        background-color: #868686;
    }
    // .developer-portal-nav {
    //     border-width: "20%";
    // }
}

$breakpoint-mobile: 700px;
@media (max-width: $breakpoint-mobile) {
    .customButtonText {
        display: none;
    }
}

.api-portal-merchant-dashboard {
    .today-overview-text {
        font-size: 16px;
        font-weight: bold;
        color: #807f7d;
        font-family: 'Inter';
        margin: 10px;
        align-self: 'center';
    }

    .custom-period-button {
        padding: 8px;
        background-color: #d9d9d9;
        border-radius: 5px;
        border: 0px;
        font-size: 15px;
        font-family: 'Inter';
        color: #3a3937;
        box-shadow: '-1px 1px 0px rgba(0, 0, 0, 0.25)';
        margin: 10;
        transition: 'background-color 0.3s ease';
    }
}

.custom-date-input {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.custom-date-wrapper {
    position: relative;
    width: 100%;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background-color: #222121;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-right: -10px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #969696;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #696969;
}

.header6-color-white {
    color: white;
}

.bg-color-white {
    background-color: white;
}
