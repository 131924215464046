@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500&display=swap');

:root {
    --primary-color: #ff9300;
}

.disabled-div {
    pointer-events: none;
}

.btn-circle {
    padding-right: 27px;
}

/*  DELIVERY VIEW  */

.delivery-map-pin-container {
    margin-top: 25px !important;
    padding-right: 27px;
}

.delivery-search-pin-container {
    margin-top: 25px !important;
    padding-right: 27px;
}

.delivery-contact-refresh-container {
    margin-top: 28px !important;
    padding-right: 26px;
    /* padding-top: 10px; */
}

.delivery-map-pin-container-two {
    margin-top: 40px !important;
    padding-right: 27px;
}

.order-map-pin-container {
    margin-top: 25px !important;
    padding-right: 26px;
}

.map-pin-holder {
    margin-bottom: -5px;
}

.image-upload-avatar {
    cursor: pointer !important;
}

.custom-popover {
    z-index: 1151 !important;
}

.popover {
    z-index: 1151 !important;
}

/* ORDER AND DELIVERY VIEW */

/* No greater than 900px, no less than 400px */
@media (max-width: 1400px) and (min-width: 765px) {
    .order-map-pin-container {
        margin-left: -10px !important;
    }
}

/* No greater than 900px, no less than 400px */
@media (max-width: 1600px) and (min-width: 765px) {
    .delivery-map-pin-container {
        margin-left: -10px !important;
    }

    .delivery-map-pin-container-two {
        margin-left: -10px !important;
    }

    .delivery-contact-refresh-container {
        margin-left: -10px !important;
    }

    .delivery-search-pin-container {
        margin-left: -10px !important;
    }
}

@media only screen and (min-width: 1400px) {
    .delivery-map-pin-container {
        margin-left: -10px !important;
    }

    .delivery-map-pin-container-two {
        margin-left: -10px !important;
    }

    .delivery-contact-refresh-container {
        margin-left: -10px !important;
    }

    .delivery-search-pin-container {
        margin-left: -10px !important;
    }

    .order-map-pin-container {
        margin-left: -5px !important;
    }
}

/* ------------------------------------------------------- -*/

@media only screen and (min-width: 1100px) {
    .page-center {
        padding-right: 300px !important;
    }
}

.page-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
}

/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
.dot-elastic {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: grey;
    color: grey;
    animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-elastic::before {
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: grey;
    color: grey;
    animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
    left: 15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: grey;
    color: grey;
    animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1.5);
    }

    50% {
        transform: scale(1, 0.67);
    }

    75% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElastic {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 1.5);
    }

    75% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElasticAfter {
    0% {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 0.67);
    }

    75% {
        transform: scale(1, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}

.hide-scroll {
    height: 100vh !important;
    overflow-y: hidden !important;
}

.full-screen {
    height: 100%;
}

.screen-height-90 {
    height: 85%;
}

.fade-out-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.animate-out-right-toast {
    position: absolute;
    right: -500px;
    transition: 0.5s;
}

.dsg-add-row {
    display: none;
}

.react-bootstrap-table {
    overflow: auto;
    display: block;
    table-layout: auto;
}

.navbar-header {
    min-height: 100px !important;
}

.main-content {
    margin-top: 20px !important;
}

.vertical-menu {
    margin-top: 20px !important;
}

.navbar-brand-box {
    min-height: 100px !important;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.logo-light {
    margin-top: 10px;
    padding-left: 20px;
}

/** Mobile UI Fixes */

@media only screen and (max-width: 990px) {
    .navbar-brand-box {
        display: none !important;
    }
    .container-fluid {
        margin-top: 40px !important;
    }
}

@media only screen and (max-width: 1440px) {
    .header-text {
        font-size: 10px;
    }
    .header-subtitle {
        font-size: 10px;
    }
}

@media only screen and (max-width: 650px) {
    .header-heading {
        font-size: 10px;
        white-space: nowrap;
    }

    .header-text {
        font-size: 7px;
    }

    .header-subtitle {
        font-size: 6px;
    }

    .header-status {
        font-size: 6px;
        text-align: center;
        white-space: nowrap;
    }

    #delivery-shipping-label-btn {
        margin-left: 40px !important;
    }

    .delivery-view-map-locations-pin {
        display: none !important;
    }

    .delivery-view-map-locations-title {
        margin-left: -12px !important;
    }

    .delivery-view-progress-text {
        margin-left: 5px !important;
    }

    .form-field-icon-delivery {
        margin-left: -2px !important;
    }

    .stat-card-container {
        /* max-width: 450px !important; */
        min-width: 90vw !important;
    }
}

/* ------------------------ */

.delivery-additional-info-icon-container {
    /* padding-top: -20px !important; */
    align-items: center;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    display: flex;
}

.delivery-additional-info-icon {
    margin-top: 2px;
    margin-left: 1px;
}

@media only screen and (min-width: 1200px) {
    .delivery-form-pickup-location-desc {
        margin-left: -20px !important;
    }

    .delivery-form-delivery-address-desc {
        margin-left: -20px !important;
    }
}

.maintenance-container {
    /* z-index: 10000 !important; */
    margin-bottom: -15px;
}

.custom-popover .popover {
    margin-left: 40px !important;
}

/* Advance Tracking Riders */
@media only screen and (min-width: 1200px) {
    .advanced-tracking-map-lottie {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 35%;
    }
}

.rider-advanced-tracking-mobileNo {
    border: none !important;
    text-align: center;
    color: #505d69;
}

.font-poppins {
    font-family: 'Poppins', sans-serif;
}

.delivery-import-setting-model-search {
    margin-top: 28px !important;
    margin-left: -10px !important;
}

.delivery-import-setting-model-search-icon {
    margin-top: -2px !important;
}

/*=======  Back to top  =======*/

.back-to-top {
    position: fixed;
    right: 0;
    bottom: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fcd462;
    z-index: 99;
    color: #fff;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
    right: 20px;
    padding-bottom: 30px !important;
}

.delivery_records_map {
    columns: 3 auto;
}

.back-to-bottom {
    position: fixed;
    right: 0;
    bottom: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fcd462;
    z-index: 99;
    color: #fff;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
}

.back-to-bottom.active {
    visibility: visible;
    opacity: 1;
    right: 20px;
    padding-bottom: 30px !important;
}

/** START Select Changes  */
#layout-wrapper
    > div.main-content
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div.css-1hb7zxy-IndicatorsContainer
    > div
    > svg {
    color: var(--primary-color);
}

#layout-wrapper
    > div.main-content
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div.css-1hb7zxy-IndicatorsContainer
    > span {
    display: none;
}

#layout-wrapper > div.main-content > div > div > div > div > div > div > div > div > div > div {
    border-radius: 10px;
    /* border:1px solid #F1F1F1 */
}

/** END Select Changes  */

.modal-content {
    border: none;
}
