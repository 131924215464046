.modal__form {
    width: 527px;
    border-radius: 14px;
    background-color: #ffffff;
}

.modal__form-header {
    height: 47px;
    background-color: rgb(255, 175, 38);
    border-radius: 5px 14px 0px 0px;
}

.modal__form-header p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 15px 5px 5px 5px;
    margin-left: 20px;
}

.modal__form-table {
    padding: 15px 25px 35px 25px;
}

.modal_form-item {
    margin-bottom: 12px;
}

/* .modal__form-alert {
    background: #fffbf0;
    margin-top: 15px;
    border: 1px solid #ffedb7;
    margin-bottom: 15px;
    padding: 10px;
    width: 100px;
} */

/* .modal__form-alert p {
    padding: 10px;
    color: #807f7d;
    margin-bottom: 0;
} */

.modal__form-alert {
}
