.remove__btn {
    background-color: #fd4a4a !important;
    padding-left: 12px;
    border-radius: 10px;
}
.divider {
    color: #807f7d;
    border-width: 3px;
}

.vehicle-type {
    width: 90%;
    height: auto;
    max-width: 60px;
}

.disabled {
    opacity: 0.5;
}
