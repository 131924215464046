.map-grid {
    position: relative;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: #807f7d;

    .map-grid__map-container {
        z-index: 0;
        height: 140vh;
        border-radius: 12px;
        width: 100wh;
    }

    .map-grid__modal-opener {
        display: flex;
        width: 4%;
        height: 200px;
        /* IE10 Consumer Preview */
        background-image: -ms-linear-gradient(bottom right, #f09953 100%, #ffcf4b 0%);
        /* Mozilla Firefox */
        background-image: -moz-linear-gradient(bottom right, #f09953 100%, #ffcf4b 0%);
        /* Webkit (Chrome 11+) */
        background-image: -webkit-linear-gradient(bottom right, #ffcf4b 0%, #f58221 100%);
        position: absolute;
        top: 40%;
        right: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        justify-content: center;

        .text {
            /* Abs positioning makes it not take up vert space */
            position: absolute;
            color: white;
            top: 37%;
            font-size: 100%;
            white-space: nowrap;
            transform: rotate(-90deg);
        }

        .icon {
            position: absolute;
            top: 74%;
        }
    }
}
