.rider-on-map {
    .rider-on-map__marker-online {
        height: 30px;
        width: 30px;
        background-color: #9dffb9;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #2ea31b;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .rider-on-map__marker-offline {
        height: 30px;
        width: 30px;
        background-color: #e3e2de;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .rider-on-map__marker-home {
        height: 30px;
        width: 30px;
        background-color: #ffffff;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #ff9300;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .rider-on-map__marker-tasa {
        height: 30px;
        width: 30px;
        background-color: #000000;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #ff9300;
        display: inline-block;
        text-align: center;
    }
}
