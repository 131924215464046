.card.text-muted {
    font-weight: 700;
}

.avatar-online {
    position: relative;

    .sb-avatar__text {
        border: 2px solid #26c60c;
        box-sizing: border-box;
    }
}

img.avatar-online {
    border: 2px solid #26c60c;
}

.avatar-online:after {
    content: '';
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #26c60c;
}

.check-circle-success {
    color: #0f891c;
    font-size: 20px;
}

.route-planning-entry__card {
    height: 100%;
    margin-bottom: 0px !important;
}
