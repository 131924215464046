.assign-rider__modal {
    /** Override the styles of react select dropdown*/
    .select-deselect__fields {
        /** remove the dropdown arrow icon in the component*/
        .css-1hb7zxy-IndicatorsContainer {
            display: none;
        }

        .css-12jo7m5 {
            margin-top: 1px;
        }

        .deselected-deliveries__form-group {
            svg {
                display: inline-block;
                background-image: url('../../../../../../../../../../../../assets/icons/Plus.svg');
                background-repeat: no-repeat;
                margin-top: 1px;

                path {
                    display: none;
                }
            }
        }
        .selected-deliveries__form-group {
            svg {
                margin-top: 1px;
            }
        }
    }
}

.assign-rider__modal__map-grid {
    position: relative;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: #807f7d;

    .assign-rider__modal__map-grid__map-container {
        z-index: 0;
        height: 80vh;
        border-radius: 12px;
        width: 100%;
    }
}

// styling for end destination of the riders paths
.destination-on-map {
    .destination-on-map__marker {
        height: 18px;
        width: 18px;
        background-color: #398bff;
        border-radius: 50%;
        border-width: 1px;
        display: inline-block;
        text-align: center;
    }
}
