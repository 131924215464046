.printer-settings__card{
    padding-bottom: 30px;
    padding-top: 100px;
    margin-left: 10px;
    margin-right: 10px;
    height: 267px;
    margin-bottom: 110px;
}

.printer-settings__footer{
    position: absolute;
    width: -webkit-fill-available;
    height: 44px;
    left: 0px;
    top: 230px;

    background: #F0F0F0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 14px 14px;
}

.printer-settings__footer-text{
    width: 403px;
    height: 17px;
    margin-left: 14px;
    margin-top: 14px;
    white-space: nowrap;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #807F7D;
}

@media (max-width: 500px ) {
    .printer-settings__card{
        padding-bottom: 30px;
        padding-top: 100px;
        margin-left: 10px;
        margin-right: 10px;
        height: 360px;
        margin-bottom: 2200px;
        margin-top: 145px;
    }

    .printer-settings__footer-text{
        width: 403px;
        height: 17px;
        margin-left: 14px;
        margin-top: 14px;
    
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
        /* identical to box height */
    
    
        color: #807F7D;
    }
    .printer-settings__footer{
        position: absolute;
        width: -webkit-fill-available;
        height: 44px;
        left: 0px;
        top: 314px;
        margin-top: 3px;
        background: #F0F0F0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 14px 14px;
    }
}