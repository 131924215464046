@page {
    /* size: 2.5in 4in; */
    margin-left: -50px !important;
    margin-right: -50px !important;
    margin-top: -20px !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.from-address {
    font-size: 10px;
}

.to-address {
    font-size: 10px;
}

.order-reference-title {
    font-size: 10px;
}

.order-reference {
    font-size: 16px;
}

.qr-code-print {
    display: none;
}

.scootix-ref {
    font-size: 10px;
}

.packages-length {
    font-size: 20px;
}

@media print {
    body,
    html {
        /* width: 230px !important; */
        /* height: 1700px !important; */
        /* margin: 3mm; */
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
        /* padding-left: -190px !important; */
    }
    /* .print-card {
        width: 1230px !important;
        height: 1600px !important;
        margin: 0;
        border: 1px solid #000;
    } */

    .to-address {
        font-size: 40px !important;
    }

    .line-1 {
        padding: 80px;
        padding-bottom: 10px !important;
    }
    .line-2 {
        padding: 90px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .line-3 {
        padding: 90px !important;
        padding-top: 10px !important;
        padding-bottom: 0px !important;
    }

    .from {
        font-size: 45px !important;
    }

    .to {
        font-size: 30px;
    }

    .from-address {
        font-size: 40px !important;
    }

    .scootix-image {
        width: 200px;
        height: 170px;
        margin-right: 30px;
    }

    .line-2 {
        padding: 50px;
        padding-left: 50px;
    }

    .line-breaker {
        /* width: 500px;
        align-self: center;
        vertical-align: middle;
        text-align: center; */
        width: 90%;
        margin-left: 5% !important;
        margin-right: 5% !important;
    }

    .line-3 {
        padding: 50px;
    }

    .order-reference-title {
        font-size: 25px;
    }
    .order-reference {
        font-size: 30px;
    }

    .line-4 {
        padding-top: 40px;
    }

    .qr-code-print {
        display: block;
        width: 250px !important;
        height: 250px !important;
    }

    .qr-code-normal {
        display: none;
    }

    .scootix-ref {
        font-size: 30px;
    }

    .packages-length {
        font-size: 40px;
    }
}
