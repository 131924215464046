.table-default {
    width: 100%;

    .table-top-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .top-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .app-search {
                margin-left: 15px;
                span {
                    position: absolute;
                    z-index: 10;
                    font-size: 16px;
                    line-height: 45px;
                    left: 23px;
                    top: 0;
                    // color: $gray-600;
                    color: $theme-color-4;
                }
            }

            .app-search .form-control {
                height: 45px;
                margin-left: 10px;
                position: relative;
            }
        }

        i {
            color: $theme-color-4;
        }
    }

    .filter-btn {
        height: 50px;
        border: 1px solid #eae6e6;
        padding: 10px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 18px;
        i {
            color: $theme-color-4;
        }
        span {
            margin-left: 5px;
        }
    }

    .settings-btn {
        height: 50px;
        border: 1px solid #eae6e6;
        padding: 10px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 18px;
        i {
            color: $theme-color-4;
        }
        span {
            margin-left: 5px;
        }
    }

    .tableHeader {
        font-size: 16px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;

        .th-row {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
        }
    }

    .pagination-row-container {
        display: flex;
        justify-content: space-between;
        .pagination-container {
            margin-right: 10px;
        }
    }
}

.picking-status-table-pending {
    background-color: #ffedb7;
    display: inherit;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px;
}

.picking-status-table-completed {
    background-color: #81ee6f;
    display: inherit;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px;
}

/* Between min and max */
@media screen and (min-height: 100px) and (max-height: 800px) {
    .table-filter-dropdown-menu {
        margin-right: -100px !important;
    }
}

/* Between min and max */
@media screen and (min-height: 800px) and (max-height: 1800px) {
    .table-filter-dropdown-menu {
        margin-right: -100px !important;
    }
}

// overriding css for Loading
.custom-table-container {
    .hide-table-scroll {
        overflow: hidden !important;
    }
}

// Table Default Search Field Styles
.table__custom__search-default {
    margin-left: 15px;
    .search-icon {
        position: absolute;
        font-size: 16px;
        line-height: 45px;
        left: 23px;
        top: 0;
        color: $theme-color-4;
    }

    .close-icon {
        position: absolute;
        font-size: 16px;
        line-height: 45px;
        right: 15px;
        top: 0;
        color: $theme-color-4;
    }

    .form-control {
        padding-left: 40px;
        padding-right: 40px;
        border: none;
        height: 38px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 10px;
        height: 45px;
        margin-left: 10px;
    }
}

// Table Search Field in Right Styles
.table__custom__search-right {
    position: relative; /* add position relative to the container */
    .search-icon {
        position: absolute;
        font-size: 16px;
        line-height: 45px;
        right: 0; /* change icon left to right */
        top: 0;
        color: $theme-color-4;
    }

    .close-icon {
        position: absolute;
        font-size: 16px;
        line-height: 45px;
        right: 15px;
        top: 0;
        color: $theme-color-4;
    }

    .form-control {
        padding-left: 10px;
        padding-right: 40px;
        border: none;
        height: 38px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 10px;
        height: 45px;
        margin-left: 10px;
    }
}

// shipping table header styles
.table__custom__search-shipping {
    margin-left: 5px;
    span {
        position: absolute;
        font-size: 16px;
        line-height: 45px;
        left: 23px;
        top: 0;
        color: #ff9300;
    }
    ::placeholder {
        color: #ff9300;
        font-size: 12px;
    }

    .form-control {
        padding-left: 40px;
        padding-right: 40px;
        border: none;
        height: 38px;
        width: 363px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 10px;
        height: 45px;
        margin-left: 10px;
    }
}

.table__custom__lable-shipping {
    margin-left: 10px;
    Label {
        display: inline;
        width: 89px;
        height: 0px;
        left: 697px;
        top: 526px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-top: 13px;
        margin-left: 35px;
    }
}

.table__custom__date-shipping {
    .form-control {
        padding-left: 14px;
        padding-right: 16px;
        border: none;
        height: 38px;
        width: 220px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 10px;
        height: 43px;
        margin-left: 25px;
        margin-top: 13px;
    }
}

.table-sorting-arrow {
    color: #595a5c;
    font-size: small;
    margin-left: 5px;
}
