.header-additional-labels__container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 10px;
}

.partner-api-service__header {
    background: #df0e0e;
    border-radius: 5px;
    color: #fff;
    padding: 5px;
}

.service-active {
    background: #26c60c;
}
