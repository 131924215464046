// Table Pagination Container
.pagination-container {
    .page-item.active .page-link {
        background-color: $theme-color-4;
        border-color: $theme-color-4;
    }
}

.order-processing-dashboard {
    .nav-tabs-custom {
        .nav-item {
            width: 200px;
            text-align: center;
        }
    }
}

.nav-tabs-custom .nav-item .nav-link {
    background-color: transparent !important;
    cursor: pointer;
}

.nav-tabs-custom {
    .nav-link {
    }

    span {
        display: inline !important;
        color: #303a52 !important;
    }
}

// Reactstrap Spinner
.spinner-border {
    color: $theme-color-4 !important;
}

.spinner-white {
    color: #fff !important;
}

.backdrop-spinner {
    .spinner-border {
        color: $theme-color-4 !important;
        display: block;
        position: fixed;
        top: calc(50% - (58px / 2));
        // right: calc(50% - (58px / 2));
    }
    .spinner-message {
        color: $theme-color-4 !important;
        display: block;
        position: fixed;
        top: calc(54% - (58px / 2));
        // right: calc(50% - (58px / 2));
        text-align: center;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 100vh;
        // margin-left: 10px;
    }
}

// Backdrop Style
.custom-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    background: #000000e1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000 !important;

    ::-webkit-scrollbar {
        display: none !important;
    }
}

.default-card {
    border-radius: 20px;
    border: 1px solid #e7e4e4;
}

.custom-form {
    .form-control {
        border-radius: 15px;
        // background-color: #fcfcfc;
        background-color: #f0f3f5;
        border-color: #f5f3f3;
    }

    .form-check-group {
        .form-control {
            background-color: transparent;
        }
    }
}

.default-form {
    .form-control::-webkit-outer-spin-button,
    .form-control::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.scootix-confirmation-modal {
    .modal-content {
        border-radius: 15px !important;
        border: '1px solid #E5E5E5';
    }
}

.developer-portal-nav {
    border-bottom: 1px solid #807f7d;

    .nav-item {
        background-color: #eae6e6;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .nav-item .active {
        background-color: #ff9300;
        color: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom: 1px solid #807f7d;
    }
}

// Mobile UI
@media only screen and (max-width: 650px) {
    #delivery-shipping-label-btn {
        span {
            font-size: 12px !important;
        }
    }
    .delivery-form-back-btn {
        font-size: 12px !important;
    }
    .navbar-header {
        min-height: 100px !important;
    }
    .main-content {
        margin-top: 20px !important;
    }
    .vertical-menu {
        margin-top: 20px !important;
    }
}

@media only screen and (max-width: 400px) {
    #delivery-shipping-label-btn {
        span {
            font-size: 10px !important;
        }
    }
    .delivery-form-back-btn {
        font-size: 10px !important;
    }
    .navbar-header {
        min-height: 130px !important;
    }
    .main-content {
        margin-top: 50px !important;
    }
    .vertical-menu {
        margin-top: 50px !important;
    }
}

// -------------------------
