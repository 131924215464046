@import '../../../styles/theme.scss';

.apexcharts-canvas {
    text {
        fill: $text-muted !important;
    }
}

.home-card-stat-icon {
    color: #ff9300;
}
