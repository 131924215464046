.route-planning__form-header {
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    border: 1;
    background-color: #E5E5E5;

    .header-text {
        font-size: 16px;
        font-weight: bolder;
        color: black;
        margin-left: auto;
    }

    .dot-theme_1 {
        height: 30px;
        width: 30px;
        background-color: #f89322;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: black;
        display: inline-block;
    }

    .dot-theme_2 {
        height: 30px;
        width: 30px;
        background-color: #8b83ba;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: black;
        display: inline-block;
    }

    .dot__badge-text {
        margin-top: 7px;
    }

}