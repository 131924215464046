/* =============
   Stat Card
============= */

@media (max-width: 767px) {
    .stat-card-container {
        // min-width: 100vw;
    }
}

@media (min-width: 767px) {
    .stat-card-container {
        min-width: 280px;
    }
}

.stat-card-container {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
}