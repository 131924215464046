.route-planning__form {
    .form-group {
        display: flex;
        flex-direction: column;
    }
    .route-planning__btn {
        border-radius: 10px;
    }
    .remove__btn {
        background-color: #fd4a4a !important;
        padding-left: 12px;
        border-radius: 10px;
    }
    .divider {
        color: #807f7d;
        border-width: 3px;
    }

    /** tooltip stylings*/
    .tooltip-container {
        position: relative;
        display: inline-block;
    }
    .tooltip-text {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        font-size: 0.8rem;
        opacity: 0;
        transition: opacity 0.3s;
    }
    .tooltip-text::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
    .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}
