.route-plan__entry-view__map-grid {
    position: relative;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: #807f7d;

    .route-plan__entry-view__map-grid__map-container {
        z-index: 0;
        height: 50vh;
        border-radius: 12px;
        width: 100%;
    }
}