//
// authentication.scss
//



.auth-body-bg {
    background-color: $card-bg;
}

.authentication-bg {
    background-image: url('../../../images/app/dark-bg.png');
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    // .bg-overlay{
    //     background-color: #292626;
    // }

    h3 {
        color: #fff;
    }

    @media (max-width: 991px) {
        display: none;
    }
}

.authentication-page-content {
    position: relative;

    .row-container{
        margin-top: 100px;
    }
}

.authentication-page-content::before {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: url('../../../images/app/Malaysia-1.png');
    opacity: 0.2;
}

.authentication-page-content {
    .auth-body {
        position: relative;
        z-index: 2;
    }
}

.auth-form-group-custom {
    position: relative;
    .form-control {
        height: 60px;
        padding-top: 28px;
        padding-left: 60px;
        border-radius: 30px;
        border: 2px solid $theme-color;
    }

    label {
        position: absolute;
        top: 7px;
        left: 60px;
    }

    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $theme-color;
    }
}

.auth-login-btn {
    border-radius: 30px;
    background-color: $theme-color;
    border: none;
    min-width: 130px !important;
    width: 200px;
}

.auth-login-btn:hover {
    background-color: #f56342;
}

.auth-login-btn-back-to-login {
    border-radius: 20px;
    background-color: $theme-color-3;
    border: none;
    min-width: 130px !important;
    width: 200px;
}

.auth-login-btn-back-to-login:hover {
    background-color: #f56342;
}

// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
    z-index: 9;
}
