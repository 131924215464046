.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 16px;
        line-height: 1.43;
        font-size: 13px;
        min-width: 32px;

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }

        &.selected {
            background-color: #ff9300;
            color: #fff;
        }

        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: '';
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
            }

            &.right {
                transform: rotate(45deg);
            }
        }

        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(0, 0, 0, 0.43);
                border-top: 0.12em solid rgba(0, 0, 0, 0.43);
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }

    .results-per-page-container {
        padding: 0 12px;
        text-align: center;
        color: #807f7d;
        font-size: 13px;

        .results-per-page {
            height: 32px;
            border-radius: 8px;
            width: 50px;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            /* SVG background image */
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzU3MzNfNDA0KSI+CjxwYXRoIGQ9Ik0xMiA1VjE5IiBzdHJva2U9IiM4MDdGN0QiIHN0cm9rZS13aWR0aD0iMS4yNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xOCAxM0wxMiAxOSIgc3Ryb2tlPSIjODA3RjdEIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNiAxM0wxMiAxOSIgc3Ryb2tlPSIjODA3RjdEIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzU3MzNfNDA0Ij4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
            background-position: calc(100%) center;
            background-repeat: no-repeat;
            padding-left: 10px;
            background-size: 40%;
        }
    }
}
