//** stylings for the selected delivery modal*/
.route-planning__grid__selected-delivery__modal {
    display: flex;
    position: absolute;
    width: auto;
    max-width: 80%;
    max-height: 80%;
    height: auto;
    top: 40%;
    right: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    .selected-delivery__modal__card-header {
        background-color: #f58221;
        color: white;
        justify-content: space-between;
        align-items: center;
        display: flex;
        border-top-left-radius: 15px;
    }
    .selected-delivery__modal__card-body {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .selected-delivery__modal-body__text {
        font-size: 1.7vw;
    }

    .selected-delivery__modal__card-header__title {
        flex: 1;
    }

    /** override the is fixed height of the customTable - only for this modal */
    .table-fixed-height {
        max-height: 250px !important;
    }

    .remove__btn {
        background-color: #fd4a4a !important;
        padding-left: 12px;
        border-radius: 10px;
    }
    .close-btn {
        margin-left: 10px;
        cursor: pointer;
    }
}
